.app-container {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #dfae4e7a; // Main background color
  color: #1c1c1c; // Darker text for better contrast
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  padding-top: 30px; // Increased padding to give more space at the top
}

.content-wrapper {
  background-color: rgba(255, 255, 255, 0.438); // Slightly transparent white
  border-radius: 20px;
  padding: 30px;
  max-width: 800px;
  width: 100%;
  padding-top: 30px; // Increased padding inside the content wrapper
}

.app-title {
  font-size: 2.2rem; // Slightly reduced font size to balance with larger logo
  font-weight: bold;
  margin-top: 10px; // Added a small top margin for spacing after the logo
  text-align: center;
  color: #333333; // Darker text for better contrast
}

.app-description {
  font-size: 1.1rem; // Slightly reduced font size for better proportion
  text-align: center;
  margin-bottom: 30px;
  color: #333333; // Darker text for better contrast
}

.feature-list {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.feature-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333333; // Darker text for better contrast

  svg {
    margin-right: 10px;
    font-size: 1.5rem;
    fill: #333333; // Darker icon color
  }
}
