[data-component='VoiceChat'] {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  margin: 0;
  color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 12px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;

    @media (max-width: 480px) {
      margin-bottom: 15px;
    }
  }

  .jio-logo {
    max-width: 180px;
    height: auto;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 180px;
    }

    @media (max-width: 480px) {
      max-width: 160px;
    }
  }

  .app-title {
    font-size: 1.6rem;
    margin: 0 0 10px 0;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      margin: 0 0 15px 0;
    }

    @media (max-width: 480px) {
      font-size: 1.2rem;
      margin: 0 0 12px 0;
    }
  }

  .app-description {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 14px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      font-size: 0.8rem;
      margin-bottom: 15px;
    }

    @media (max-width: 480px) {
      font-size: 0.7rem;
      margin-bottom: 12px;
      max-width: 90%;
    }
  }

  .feature-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 18px;
    font-size: 0.8rem;

    @media (max-width: 768px) {
      gap: 20px;
      margin-bottom: 20px;
      font-size: 0.7rem;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
      font-size: 0.6rem;
    }
  }

  .content-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-width: 600px;
    width: 90%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 10px;

    @media (max-width: 768px) {
      width: 95%;
      padding: 15px;
      border-radius: 15px;
    }

    @media (max-width: 480px) {
      width: 90%;
      padding: 10px;
      border-radius: 12px;
    }

    .section-title {
      margin-bottom: 18px;
      color: #000000;
    }
  }

  .visualization-container {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      max-width: 350px;
      padding: 12px;
      margin-bottom: 15px;
    }

    @media (max-width: 480px) {
      max-width: 300px;
      padding: 10px;
      margin-bottom: 12px;
    }
  }

  .visualization {
    margin: 0 auto;
    display: flex;
    border-radius: 10px;
    width: 100%;
    height: 75px;

    @media (max-width: 768px) {
      height: 70px;
    }

    @media (max-width: 480px) {
      height: 60px;
    }
  }

  .content-actions,
  .phone-input-container form {
    display: flex;
    justify-content: center;
    margin-top: 18px;

    button {
      padding: 10px 20px;
      background-color: #ffffff;
      color: #000000;
      border: none;
      border-radius: 25px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        margin-right: 8px;
        stroke: #000000; // Ensure the icon is also black
      }

      &:hover {
        background-color: #f0f0f0;
        transform: translateY(-2px);
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      }

      &:active {
        transform: translateY(1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .visualization-entry {
    flex: 1;
    height: 100%;
    
    &.client {
      margin-right: 10px;
    }
    
    &.server {
      margin-left: 10px;
    }
    
    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .phone-input-container {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 13px;
    width: 90%;
    max-width: 600px;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      width: 95%;
      padding: 15px;
      border-radius: 15px;
    }

    @media (max-width: 480px) {
      width: 90%;
      padding: 10px;
      border-radius: 12px;
    }

    .section-title {
      margin-bottom: 20px;
      color: #000000;
    }

    h2 {
      color: #000000;
      font-size: 1.1em;
      margin-bottom: 15px;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .phone-input-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      width: 100%;
      max-width: 300px;
    }

    .country-code {
      background-color: rgba(255, 255, 255, 0.1);
      color: #000000;
      padding: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-right: none;
      border-radius: 4px 0 0 4px;
      font-weight: bold;
    }

    input[type="tel"] {
      flex-grow: 1;
      padding: 8px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #000000;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 0 4px 4px 0;
      font-size: 14px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .submit-button {
      padding: 10px 20px;
      background-color: #dfae4e7a; // Adjust this to match your page's background color
      color: #ffffff; // White text for contrast
      border: none;
      border-radius: 25px; // This makes the button rounded
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s;

      &:hover {
        background-color: #007acc; // A slightly darker shade for hover effect
        transform: scale(1.05); // Slight grow effect on hover
      }

      &:active {
        transform: scale(0.95); // Slight shrink effect when clicked
      }
    }
  }

  .section-title {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 18px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #000000;
    font-weight: normal;

    @media (max-width: 768px) {
      font-size: 0.8rem;
      margin-bottom: 15px;
    }

    @media (max-width: 480px) {
      font-size: 0.7rem;
      margin-bottom: 12px;
      max-width: 90%;
    }
  }
}
